import request from '../utils/request';

/**
 * 调用批量挪课时
 */
export const statisticsByDivision = (query) => {
    return request({
        url: '/sys/crmCustomers/statisticsByDivision',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(query),
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        }
    });
};
export const exportCrmNewCustomers = (query) => {
    return request({
        url: '/sys/crmCustomers/exportCrmNewCustomers',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(query),
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        }
    });
};